<template>
	<div id="admin">
		<h1>View & Manage Orders</h1>
		<div v-if="!token">
			<form @submit.prevent="login()">
				<h2>Admin Login</h2>
				<input
					type="email"
					placeholder="Email"
					v-model="formData.email"
				/>
				<input
					type="password"
					placeholder="Password"
					v-model="formData.password"
				/>
				<input type="submit" value="Login" id="submit" />
			</form>
		</div>
		<div v-else>
			<button @click="logout()">Logout</button>
			<div id="orders">
				<div
					class="order"
					v-for="(order, index) in orders"
					:key="index"
				>
					<p
						v-text="'Company name: ' + order[0].brandDetails.name"
					></p>
					<p
						v-text="
							'Name: ' + order[0]?.you?.name
								? order[0]?.you?.name
								: order[0]?.youDetails?.name
						"
					></p>
					<p
						v-text="
							'Email: ' + order[0]?.you?.email
								? order[0]?.you?.email
								: order[0]?.youDetails?.email
						"
					></p>
					<router-link
						:to="{
							name: 'Order',
							params: {
								orderId: order[1]
							}
						}"
						target="_blank"
						>View Order</router-link
					>
					<br />
					<router-link
						:to="{
							name: 'BrandKit',
							params: {
								orderId: order[1]
							}
						}"
						target="_blank"
						>Manage Order</router-link
					>
					<hr />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { fireGetAll, fireAsyncSignIn } from '../db';
	import api from '../api';

	export default {
		name: 'Admin',
		data() {
			return {
				formData: {
					email: '',
					password: ''
				},
				orders: []
			};
		},
		mounted() {
			fireAsyncSignIn()
				.then(() => {
					if (localStorage.getItem('token')) {
						this.$store.commit(
							'setToken',
							localStorage.getItem('token')
						);
					}

					if (this.$store.state.token) {
						api.post(
							'/admin/test-token',
							{},
							{
								params: {
									token: this.$store.state.token
								}
							}
						).catch(e => {
							console.log(
								`Normal error token test: ${e.message}`
							);
							this.$store.commit('setToken', '');
							localStorage.setItem('token', '');
						});
					}

					fireGetAll()
						.then(orders => {
							this.orders = orders;
						})
						.catch(e => {
							console.log(
								`Admin firebase get all orders error: ${e.message}`
							);
						});
				})
				.catch(e => {
					console.log(`fireAsyncSignIn Error ${e.message}`);
				});
		},
		methods: {
			login() {
				api.post('/admin/login', {
					email: this.formData.email,
					password: this.formData.password
				})
					.then(res => {
						this.$store.commit('setToken', res.data.token);
						localStorage.setItem('token', res.data.token);
					})
					.catch(e => {
						console.log(`Admin login error: ${e.message}`);
						this.$store.commit('setToken', '');
						localStorage.setItem('token', '');
					});
			},
			logout() {
				this.$store.commit('setToken', '');
				localStorage.setItem('token', '');
			}
		},
		computed: {
			token() {
				return this.$store.state.token;
			}
		}
	};
</script>

<style scoped>
	#admin {
		margin-top: 15vh;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}

	h1 {
		text-align: center;
		color: var(--accent);
		padding: 30px;
		background-color: var(--light-grey);
	}

	form {
		text-align: center;
		width: 70%;
		margin: 0 auto;
	}

	input {
		margin: 7px auto;
		text-align: center;
		width: 100%;
		padding: 8px;
		box-sizing: border-box;
	}

	#submit:hover,
	#submit:active {
		cursor: pointer;
		background-color: var(--accent);
		color: var(--white);
	}
</style>
